import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/auth-callback": [4],
		"/gem": [5],
		"/organization/[orgId]/location/[locId]": [6,[2]],
		"/organization/[orgId]/location/[locId]/86": [7,[2]],
		"/organization/[orgId]/location/[locId]/cart-rule/add": [8,[2]],
		"/organization/[orgId]/location/[locId]/catalogs": [10,[2]],
		"/organization/[orgId]/location/[locId]/catalog": [9,[2]],
		"/organization/[orgId]/location/[locId]/dashboard": [11,[2]],
		"/organization/[orgId]/location/[locId]/deployments": [12,[2]],
		"/organization/[orgId]/location/[locId]/deployments/create": [14,[2]],
		"/organization/[orgId]/location/[locId]/deployments/preview/[id]": [15,[2]],
		"/organization/[orgId]/location/[locId]/deployments/[id]": [13,[2]],
		"/organization/[orgId]/location/[locId]/diagnostic": [16,[2]],
		"/organization/[orgId]/location/[locId]/discounts": [17,[2]],
		"/organization/[orgId]/location/[locId]/discounts/add": [18,[2]],
		"/organization/[orgId]/location/[locId]/discounts/edit/[id]": [19,[2]],
		"/organization/[orgId]/location/[locId]/edit-nge-location/[id]": [20,[2]],
		"/organization/[orgId]/location/[locId]/gem": [21,[2]],
		"/organization/[orgId]/location/[locId]/globalSettings/cep": [22,[2]],
		"/organization/[orgId]/location/[locId]/gou/dashboards": [23,[2]],
		"/organization/[orgId]/location/[locId]/gou/groups": [24,[2]],
		"/organization/[orgId]/location/[locId]/gou/groups/[id]": [25,[2]],
		"/organization/[orgId]/location/[locId]/gou/onboardingwizard": [26,[2]],
		"/organization/[orgId]/location/[locId]/gou/organizations": [27,[2]],
		"/organization/[orgId]/location/[locId]/gou/organizations/admin-configuration/[id]": [29,[2]],
		"/organization/[orgId]/location/[locId]/gou/organizations/userGroup/[orgId]": [30,[2]],
		"/organization/[orgId]/location/[locId]/gou/organizations/userGroup/[orgId]/[ugid]": [31,[2]],
		"/organization/[orgId]/location/[locId]/gou/organizations/users/[orgId]": [32,[2]],
		"/organization/[orgId]/location/[locId]/gou/organizations/[pid]/[id]/[type]": [28,[2]],
		"/organization/[orgId]/location/[locId]/gou/roles": [33,[2]],
		"/organization/[orgId]/location/[locId]/gou/users": [34,[2]],
		"/organization/[orgId]/location/[locId]/gsh/glance": [35,[2]],
		"/organization/[orgId]/location/[locId]/gsh/platform-glance": [36,[2]],
		"/organization/[orgId]/location/[locId]/insights/aborted-orders-analytics": [37,[2]],
		"/organization/[orgId]/location/[locId]/insights/custom-reports-analytics": [38,[2]],
		"/organization/[orgId]/location/[locId]/insights/discounts-analytics": [39,[2]],
		"/organization/[orgId]/location/[locId]/insights/glance": [40,[2]],
		"/organization/[orgId]/location/[locId]/insights/order-timing-analytics": [41,[2]],
		"/organization/[orgId]/location/[locId]/insights/product-analytics": [42,[2]],
		"/organization/[orgId]/location/[locId]/insights/sales-analytics": [43,[2]],
		"/organization/[orgId]/location/[locId]/insights/store-performance-analytics": [44,[2]],
		"/organization/[orgId]/location/[locId]/insights/system-health-analytics": [45,[2]],
		"/organization/[orgId]/location/[locId]/insights/upsell-analytics": [46,[2]],
		"/organization/[orgId]/location/[locId]/insights/upsell-summary": [47,[2]],
		"/organization/[orgId]/location/[locId]/insights/user-behavior-analytics": [48,[2]],
		"/organization/[orgId]/location/[locId]/insights/view-report/[id]": [49,[2]],
		"/organization/[orgId]/location/[locId]/item-upsell": [50,[2]],
		"/organization/[orgId]/location/[locId]/item-upsell/add": [51,[2]],
		"/organization/[orgId]/location/[locId]/kioskdatasets": [53,[2]],
		"/organization/[orgId]/location/[locId]/kioskdatasets/[id]": [54,[2]],
		"/organization/[orgId]/location/[locId]/kiosk/orders": [52,[2]],
		"/organization/[orgId]/location/[locId]/locations": [55,[2]],
		"/organization/[orgId]/location/[locId]/loyalty": [56,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager": [64,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/catalog": [65,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/category/add": [67,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/category/[id]": [66,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-component/[id]": [69,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-families/add": [71,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-families/[id]": [70,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo/[id]": [68,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/item/add": [73,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/item/[id]": [72,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier-code/[id]": [75,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier-group/[id]": [76,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier/[id]": [74,[2]],
		"/organization/[orgId]/location/[locId]/menudatasets": [77,[2]],
		"/organization/[orgId]/location/[locId]/menudatasets/[id]": [78,[2]],
		"/organization/[orgId]/location/[locId]/menu/category/add": [57,[2]],
		"/organization/[orgId]/location/[locId]/menu/category/edit/[id]": [58,[2]],
		"/organization/[orgId]/location/[locId]/menu/item/[id]": [59,[2]],
		"/organization/[orgId]/location/[locId]/menu/modifier-group/add": [61,[2]],
		"/organization/[orgId]/location/[locId]/menu/modifier-group/edit/[id]": [62,[2]],
		"/organization/[orgId]/location/[locId]/menu/modifier/[id]": [60,[2]],
		"/organization/[orgId]/location/[locId]/menu/sync": [63,[2]],
		"/organization/[orgId]/location/[locId]/order-upsell": [79,[2]],
		"/organization/[orgId]/location/[locId]/order-upsell/add": [80,[2]],
		"/organization/[orgId]/location/[locId]/payment": [81,[2]],
		"/organization/[orgId]/location/[locId]/pos/connector": [82,[2]],
		"/organization/[orgId]/location/[locId]/pos/connector/[id]": [83,[2]],
		"/organization/[orgId]/location/[locId]/pos/connector/[id]/snapshot-groups": [84,[2]],
		"/organization/[orgId]/location/[locId]/pos/connector/[id]/snapshot-groups/[snapshotGroupId]": [85,[2]],
		"/organization/[orgId]/location/[locId]/pos/integration": [86,[2]],
		"/organization/[orgId]/location/[locId]/pos/integration/[id]": [87,[2]],
		"/organization/[orgId]/location/[locId]/pos/integration/[id]/[connectorId]": [88,[2]],
		"/organization/[orgId]/location/[locId]/settings/abtesting": [89,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/colors": [90,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/fonts": [91,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/images": [92,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/kiosk-appearance": [93,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/textoverrides": [94,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/business-hours": [95,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/disclaimer": [96,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/features": [97,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/legal-copy": [98,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/locale": [99,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/message-guards": [100,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/ordering-options": [101,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/receipts": [102,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/timeouts": [103,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/tips": [104,[2]],
		"/organization/[orgId]/location/[locId]/settings/kiosks": [105,[2]],
		"/organization/[orgId]/location/[locId]/settings/kiosks/[id]": [106,[2]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers": [107,[2]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers/add": [109,[2]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers/[id]/edit": [108,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/access": [110,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/info": [111,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/setup-checklist": [112,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/staff": [113,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/staff/add": [114,[2]],
		"/organization/[orgId]/location/[locId]/settings/loyalty": [115,[2]],
		"/organization/[orgId]/location/[locId]/settings/order-types": [116,[2]],
		"/organization/[orgId]/location/[locId]/settings/payment": [117,[2]],
		"/organization/[orgId]/location/[locId]/settings/pos": [118,[2]],
		"/organization/[orgId]/location/[locId]/template/notification-template": [119,[2]],
		"/organization/[orgId]/location/[locId]/template/notification-template/[id]": [120,[2]],
		"/organization/[orgId]/location/[locId]/upsells": [121,[2]],
		"/product-mix": [122],
		"/profile": [123],
		"/sales": [124],
		"/select-organization": [125],
		"/speed-of-service": [126],
		"/welcome": [127]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';